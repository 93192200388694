/* ----------------------------------------------------------------------------
show an error for bad login passwords
---------------------------------------------------------------------------- */
export default class LoginError extends HTMLElement {
  connectedCallback() {
    this.target = this.querySelector('input[type=password]');
    const urlParams = new URLSearchParams(window.location.search);
    const badPass = urlParams.get('error');

    if (badPass === 'true') {
      this.target.classList.add('animate', '--shake');
    }
  }
}

window.customElements.define('login-error', LoginError);
