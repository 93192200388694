/* ----------------------------------------------------------------------------
 grab a random url from a json file, excluding the current page
---------------------------------------------------------------------------- */
export default class randomUrl extends HTMLElement {
  async connectedCallback() {
    const linkElement = this.querySelector('a');
    const resp = await fetch('/random.json');
    const data = await resp.json();
    const currentUrl = window.location.pathname;
    let random = data[Math.floor(Math.random() * data.length)];
    while (random.url === currentUrl) {
      random = data[Math.floor(Math.random() * data.length)];
    }
    linkElement.href = random.url;
  }
}

window.customElements.define('random-url', randomUrl);
