/* ----------------------------------------------------------------------------
creates style-able elements with random sizes and positions
---------------------------------------------------------------------------- */

export default class RandomPattern extends HTMLElement {
  static get observedAttributes() {
    return ['quantity', 'scale', 'jitter'];
  }

  get quantity() {
    return this.hasAttribute('quantity') ? this.getAttribute('quantity') : '30';
  }

  get scale() {
    return this.hasAttribute('scale') ? this.getAttribute('scale') : '0';
  }

  get jitter() {
    return this.hasAttribute('jitter') ? this.getAttribute('jitter') : '0';
  }

  static random(min, max) {
    const minimum = Math.ceil(min);
    const maximum = Math.floor(max);
    return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
  }

  generateElements() {
    this.innerHTML = '';
    const classes = ['p1', 'p2', 'p3', 'p4'];

    for (let i = 0; i < this.quantity; i += 1) {
      const rClass = classes[Math.floor(Math.random() * classes.length)];
      const rScale = RandomPattern.random(this.scale / 2, this.scale * 1.5);
      const rX = RandomPattern.random(this.jitter * -1, this.jitter);
      const rY = RandomPattern.random((this.jitter / 2) * -1, this.jitter / 2);
      const block = document.createElement('div');
      block.classList.add(rClass);

      if (this.scale > 0 && this.jitter > 0) {
        block.style.transform = `translate(${rX}rem, ${rY}rem) scale(${rScale}%)`;
      } else if (this.jitter > 0 && this.scale <= 0) {
        block.style.transform = `translate(${rX}rem, ${rY}rem)`;
      } else if (this.scale > 0 && this.jitter <= 0) {
        block.style.transform = `scale(${rScale}%)`;
      }
      this.appendChild(block);
    }
  }

  connectedCallback() {
    this.ariaHidden = 'true';
    this.generateElements();
  }
}

window.customElements.define('random-pattern', RandomPattern);
